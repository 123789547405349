<template>
  <div class="home" :class="{ 'is-guide': showGuideImages, 'is-customer': !showGuideImages }">
    <header>
      <img alt="Kuenz Naturbrennerei" src="../../assets/img/logo.png" />
      <FormGuideLogin @onOpenDialog="handleOpenGuideLogin"/>
    </header>
    <div class="wrapper-welcome-form" v-if="!showGuideImages">
      <ContentWelcome />
      <FormCustomer :showForm="!showImage && sentMailTo == ''" @onResponse="handleCustomerFormResponse" @onShowGdpr="showGdpr = true" />
      <ContentSentLink :email="sentMailTo" :showMessage="sentMailTo" @onClickBack="handleSentMessageClose" />
    </div>
    <div class="wrapper-image-customer" v-if="showImage">
      <ImageCustomer :showImage="showImage" :image="customerImage" />
    </div>
    <div class="wrapper-guide">
      <ImagesGuide :showImages="showGuideImages" :images="guideImages" />
    </div>
    <GuideLoginDialog v-if="showGuideLoginDialog" :active="guideLoginDialogActive" @onClose="handleCloseGuideLoginDialog" @onLogin="handleGuideLogin" />
    <ContentGdpr :visible="showGdpr" @onClose="showGdpr=false"/>
  </div>
</template>

<script>

import { ContentWelcome, ContentGdpr, ContentSentLink, FormCustomer, ImageCustomer, FormGuideLogin, GuideLoginDialog, ImagesGuide } from '@/components/';
import { ImageController, GuideController, CustomerFormController } from '@/controller';

export default {
  name: "Home",
  components: {
    ContentWelcome,
    ContentGdpr,
    ContentSentLink,
    FormCustomer,
    ImageCustomer,
    FormGuideLogin,
    GuideLoginDialog,
    ImagesGuide
  },
  data() {
    return {
      showImage: false,
      customerImage: {
        identifier: '',
        basePath: ''
      },
      showGuideLoginDialog: false,
      guideLoginDialogActive: false,
      showGuideImages: false,
      guideImages: [],
      sentMailTo: "",
      showGdpr: false
    }
  },
  created() {
    this.emitter.on("onGuideLogout", () => {
      this.showGuideImages = false;
    });
  },
  methods: {
    handleCustomerFormResponse(success, responseData) {
      console.log('handleCustomerFormResponse', success, responseData);
      if (success) {
        this.showImage = ImageController.image != '';
        this.customerImage = ImageController.image;
        this.sentMailTo = CustomerFormController.sentEmailTo;
      }
    },
    handleOpenGuideLogin() {
      this.showGuideLoginDialog = true;
      this.$nextTick(() => {
        this.guideLoginDialogActive = true;
      });
    },
    handleCloseGuideLoginDialog() {
      this.closeGuideLoginDialog();
    },
    async handleGuideLogin(pin) {
      const postResponse = await GuideController.postLogin(pin);
      if (postResponse.data.success) {
        this.closeGuideLoginDialog();
        this.emitter.emit("onGuideLoginSuccess");
        const getResponse = await GuideController.getImages();
        if (getResponse.data.success) {
          this.guideImages = GuideController.images;
          this.showGuideImages = true;
        }
      } else {
        this.showGuideImages = true;
        this.emitter.emit("onGuideLoginError");
      }
    },
    closeGuideLoginDialog() {
      this.guideLoginDialogActive = true;
      this.$nextTick(() => {
        this.showGuideLoginDialog = false;
      });
    },
    handleSentMessageClose() {
      this.sentMailTo = "";
      this.emitter.emit("onResetCustomerForm");
    }
  },
};
</script>
